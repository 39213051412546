import { useDispatch, useSelector } from 'react-redux'
import Header from './Header'
import Sidebar from './Sidebar'
import { AppDispatch } from '@/store'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect, useState } from 'react'
import { IGameName } from '@/shared/model/gameName.model'
import axios from '../../../shared/config/axios-interceptor'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import { IGameSidebar } from '@/shared/model/gameSidebar.model'
import { IGame } from '@/shared/model/game.model'
import { RootState } from '@/reducers'
import { historySelectors } from '../HistoryManagement/historyManagement.reducer'
import { getProfile } from '../auth/auth.api'
import { formatVND } from '@/shared/utils/ultils'
import { CModalBody, CModalFooter } from '@coreui/react-pro'
import { mapBiDatCuocGroup } from '@/shared/enumeration/ResponseStatus'
import SModal from '@/components/shared/Modal/SModal'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import dayjs from 'dayjs'
import BoxKyQuayHeader from './BoxKyQuayHeader'
import BoxTienCuoc from './components/BoxTienCuoc'
import { HistoryState } from '@/shared/enumeration/historyState'

const Pk10 = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const [game, setGame] = useState<IGameName | null>(null)

  const getGame = async (id: number) => {
    try {
      const response = await axios.get('/game/' + id)
      setGame(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [chonCuoc, setChonCuoc] = useState<IBiDatCuoc[]>([])
  const [soTien, setSoTien] = useState<number>(100)
  const [boxCuoc, setBoxCuoc] = useState<IGameSidebar[]>([])
  const [ky, setKy] = useState<IGame | null>(null)
  const [load, setLoad] = useState(false)
  const [disableDatCuoc, setDisableDatCuoc] = useState(false)
  const { user } = useSelector((state: RootState) => state.authentication)
  const historys = useSelector(historySelectors.selectAll)

  const [listKetQua, setListKetQua] = useState<IGame[]>([])

  const [showModalCuoc, setShowModalCuoc] = useState(false)

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer,
  )
  const { filterState } = initialState

  const datCuocHandle = (object: any) => {
    const check = chonCuoc.find(
      (item) => item.biDatCuocId === object.biDatCuocId,
    )

    if (check) {
      let a = chonCuoc.filter(
        (item: any) => item.biDatCuocId !== object.biDatCuocId,
      )
      setChonCuoc(a)
    } else {
      setChonCuoc([...chonCuoc, { ...object, amount: soTien }])
    }
  }

  const getBoxCuoc = async () => {
    try {
      const response = await axios.get('/bidatcuoc/listcuoc/' + params?.id)
      setBoxCuoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current/' + params?.id)
      setKy(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getListKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?gameId=' +
          params?.id +
          '&status=closed&sortBy=id&sortOrder=DESC&limit=50',
      )
      setListKetQua(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getGame(Number(params?.id) ?? 1)
    getBoxCuoc()
    getKy()
    getListKetQua()
    dispatch(getProfile())
    dispatch(
      getEntities({
        ...filterState,
        userId: Number(user?.id) ?? 1,
        sortBy: 'historyId',
        sortOrder: 'DESC',
        status: null,
        state: HistoryState.SHOW
      }),
    )
  }, [load, params?.id])

  const listTien = [10, 50, 100, 300, 500, 800]

  const onChangeSoTien = (st: number, object: any) => {
    const check: any = chonCuoc.find(
      (item) => item.biDatCuocId === object.biDatCuocId,
    )
    if (!check) {
      setChonCuoc([...chonCuoc, { ...object, amount: st }])
    } else {
      setChonCuoc(
        chonCuoc.map((item) =>
          item.biDatCuocId === object.biDatCuocId
            ? { ...object, amount: st }
            : item,
        ),
      )
    }
  }

  const getSoTien = (object: any) => {
    const check: any = chonCuoc.find(
      (item) => item.biDatCuocId === object.biDatCuocId,
    )
    if (check) {
      return check.amount
    }
    return ''
  }

  const submitHanler = async () => {
    try {
      const objectWithIdZero = chonCuoc.find((obj: any) => obj.amount <= 0)

      if (objectWithIdZero) {
        ToastError('Vui lòng nhập số tiền lớn hơn 0')
        return
      }

      if ((user?.money ?? 0) < soTien * chonCuoc.length) {
        ToastError('Số dư không đủ')
        return
      }

      if (soTien < 10) {
        ToastError('Số tiền cược phải lớn hơn 10')
        return
      }

      const data = {
        money: soTien,
        kyId: ky?.id,
        listDatCuoc: chonCuoc,
      }
      const response = await axios.post('/history/create-all-web', data)
      if (response.data) {
        dispatch(getProfile())
        setShowModalCuoc(false)
        setChonCuoc([])
        ToastSuccess('Mua vé thành công')
        dispatch(
          getEntities({
            ...filterState,
            userId: Number(user?.id) ?? 1,
            sortBy: 'historyId',
            sortOrder: 'DESC',
            status: null,
            state: HistoryState.SHOW
          }),
        )
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const xacNhanDatCuoc = () => {
    if (chonCuoc.length === 0) {
      ToastError('Vui lòng chọn ít nhất 1 mục')
      return
    }
    setShowModalCuoc(true)
  }

  const [tabRightTop, setTabRightTop] = useState(0)

  const renderIndex = (length: number) => {
    if (length <= 5) return 3
    return 5
  }

  return (
    <div className="container-web home-web game-web">
      <Header />
      <BoxKyQuayHeader
        game={game}
        ky={ky}
        load={load}
        setLoad={setLoad}
        setDisableDatCuoc={setDisableDatCuoc}
      />
      <div className="page-body-web">
        {/* <Sidebar /> */}
        <div className="content-center">
          <div className="header-content-center">
            <div className="item active">Hai số</div>
          </div>

          <div className="content-content-center">
            <BoxTienCuoc setSoTien={setSoTien} soTien={soTien} />
            <div
              className="boxchon"
              style={{
                gridTemplateColumns: `repeat(${renderIndex(
                  boxCuoc.length,
                )}, 1fr)`,
              }}
            >
              {boxCuoc.map((item: IGameSidebar, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="title text-center">{item.name}</div>
                    <div className="content">
                      {item.biDatCuoc.map((it, id) => {
                        return (
                          <div
                            key={id}
                            onClick={() => datCuocHandle(it)}
                            className={
                              chonCuoc.find(
                                (item) => item.biDatCuocId === it.biDatCuocId,
                              )
                                ? 'box d-flex justify-content-between w-100 active'
                                : 'box d-flex justify-content-between w-100'
                            }
                          >
                            <div className="name" style={{ width: '40%' }}>
                              {it.name}
                            </div>
                            <div className="rate" style={{ width: 50 }}>
                              {it.rate}
                            </div>
                            <div className="amount">
                              <input
                                value={getSoTien(it)}
                                onChange={(e: any) =>
                                  onChangeSoTien(e.target.value, it)
                                }
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>

            <div
              className="px-3 d-flex justify-content-between align-items-center"
              style={{ height: 62 }}
            >
              <div>
                {chonCuoc.length} đơn{' '}
                <span style={{ color: '#ee3047' }}>
                  {formatVND(
                    chonCuoc.reduce((accumulator, currentValue: any) => {
                      return Number(accumulator) + Number(currentValue.amount)
                    }, 0),
                  )}
                </span>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div>
                  Số dư:{' '}
                  <span style={{ color: '#ee3047' }}>
                    {formatVND(user?.money ?? 0)}
                  </span>
                </div>
                <div className="btn-datcuoc" onClick={xacNhanDatCuoc}>
                  Xác nhận đặt cược
                </div>
              </div>
            </div>
          </div>

          <div className="py-2" style={{ background: '#f0f0f0' }}></div>

          <div className="mb-2 box-lich-su-cuoc">
            <div
              className="px-2 head-lich-su-cuoc d-flex align-items-center"
              style={{ height: 40, color: '#fff' }}
            >
              Đơn cược gần nhất
            </div>
            <div className="content-list">
              <div className="tab2">
                <div className="header-class d-flex px-2">
                  <div className="w-25">Loại xổ số</div>
                  <div className="w-25">Cách chơi</div>
                  <div className="w-25">Số tiền</div>
                  <div className="w-25">Thời gian đặt</div>
                  <div className="w-25">Trạng thái</div>
                </div>

                <div className="list-content-sc mx-2">
                  {historys.map((item, index) => {
                    return (
                      <div className="content-class d-flex" key={index}>
                        <div className="w-25">{item.biDatCuoc.game?.name}</div>
                        <div className="w-25">
                          {item.biDatCuoc.gameSidebar?.name} -{' '}
                          {item.biDatCuoc.name}
                        </div>
                        <div
                          className="w-25"
                          style={{ color: 'rgb(238, 48, 71)' }}
                        >
                          {formatVND(item?.money ?? 0)}
                        </div>
                        <div className="w-25">
                          {dayjs(item.createdDate).format('DD/MM/YYYY HH:mm')}
                        </div>
                        <div className="w-25">
                          {item.isWin == null || item.isWin == undefined ? (
                            'Đang chờ'
                          ) : item.isWin ? (
                            <span className="text-success">Thắng</span>
                          ) : (
                            <span className="text-danger">Thua</span>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="py-5" style={{ background: '#f0f0f0' }}></div>
        </div>

        <div className="content-right">
          <div className="item-right notify">
            <div className="header-item">
              <div
                className={
                  tabRightTop === 0 ? 'w-50 text-center' : 'w-50 text-center'
                }
                onClick={() => setTabRightTop(0)}
              >
                Mở thưởng gần nhất
              </div>
            </div>
            <div className="content-item" style={{ height: 'auto' }}>
              {tabRightTop === 0 && (
                <div className="tab1">
                  <div className="header-class d-flex">
                    <div className="w-25 text-center">Số kỳ</div>
                    <div className="w-75 text-center">Kết quả mở thưởng</div>
                  </div>

                  <div className="list-content-sc" style={{ height: 1047 }}>
                    {listKetQua?.map((item, index) => {
                      return (
                        <div className="content-class d-flex" key={index}>
                          <div
                            className="w-25 text-center"
                            style={{ color: '#89629a' }}
                          >
                            {item.id}
                          </div>
                          <div className="w-75">
                            {/* <div className="d-flex item-bi-quay justify-content-between px-3 align-items-center">
                              {Array.from(
                                { length: game?.bi ?? 10 },
                                (_, i) => i + 1,
                              ).map((_, index) => {
                                const propertyName: any = `ball${index + 1}`
                                const ball =
                                  item?.[propertyName as keyof IGame] ?? 0

                                console.log(item?.ball10)

                                // Kiểm tra và xử lý giá trị ball nếu cần
                                let ballValue: React.ReactNode = null
                                if (
                                  typeof ball === 'number' ||
                                  typeof ball === 'string'
                                ) {
                                  ballValue = ball
                                } else {
                                  ballValue = '' // hoặc giá trị mặc định phù hợp
                                }
                                return (
                                  <div key={index} className={'item item-' + ball}>
                                    {ballValue}
                                  </div>
                                )
                              })}

                            
                            </div> */}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <SModal
          alignment="center"
          visible={showModalCuoc}
          backdrop="static"
          className="custom-modal-game custom-modal-datcuoc custom-modal-datcuoc-web"
        >
          <CModalBody>
            <div>
              <div className="d-flex justify-content-between mb-3">
                <div className="fw-bold">Xác nhận đặt cược</div>
                <div>Thời hạn: {game?.minutes} phút</div>
              </div>

              <div className="px-3 mt-4">
                <div className="mb-1">
                  {game?.name} <span className="ms-4">Kỳ: {ky?.id}</span>
                </div>
                <div className="border" style={{ fontSize: 12 }}>
                  <div
                    className="header-modal-title d-flex align-items-center px-2"
                    style={{ background: '#f5f5f5', height: 40 }}
                  >
                    <div className="w-50">Cách chơi</div>
                    <div className="w-50">Số tiền đặt cược</div>
                  </div>
                  <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                    {chonCuoc.map((item: IBiDatCuoc, index: number) => {
                      console.log(item)

                      return (
                        <div
                          key={index}
                          className="content-modal-body d-flex align-items-center px-2"
                          style={{ height: 40 }}
                        >
                          <div className="w-50">
                            {item.gameSidebar?.name} - {item.name}
                          </div>
                          <div className="w-50">
                            {formatVND(Number(item?.amount ?? 0))}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="mt-3">
                  Tổng tiền:{' '}
                  <strong style={{ color: 'rgb(238, 48, 71)' }}>
                    {formatVND(
                      chonCuoc.reduce((accumulator, currentValue: any) => {
                        return Number(accumulator) + Number(currentValue.amount)
                      }, 0),
                    )}
                  </strong>
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter className="d-flex justify-content-around">
            <div
              className="cancel fw-bold cursor-pointer"
              onClick={() => setShowModalCuoc(false)}
            >
              Đóng
            </div>

            <div
              className="submit fw-bold cursor-pointer"
              onClick={submitHanler}
            >
              Xác nhận
            </div>
          </CModalFooter>
        </SModal>
      </div>
    </div>
  )
}

export default Pk10
