import Header from './Header'
import Sidebar from './Sidebar'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import { RootState } from '@/reducers'
import Nav from './Nav'
import dayjs from 'dayjs'
import { formatVND } from '@/shared/utils/ultils'
import { historySelectors } from '../HistoryManagement/historyManagement.reducer'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import FooterCustom from './FooterCustom'
import axios from '../../../shared/config/axios-interceptor'
import { CCol, CRow } from '@coreui/react-pro'

const LaiLo = () => {
  const dispatch = useDispatch<AppDispatch>()
  const historys = useSelector(historySelectors.selectAll)
  const { user } = useSelector((state: RootState) => state.authentication)

  const [data, setData] = useState<any>({})

  const getKetQua = async () => {
    try {
      const response = await axios.get('/history/lai-lo')

      setData(response.data)
    } catch (error) {
      setData([])
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    getKetQua()
  }, [])

  return (
    <div className="container-web page-rut-tien-web page-lich-su-rut-tien-web">
      <Header />
      <div className="content-header">
        <Nav />

        <div className="header-bottom pb-4">
          <div className="top d-flex mx-2">
            <div>Lãi lỗ hôm nay</div>
          </div>

          <div className="mx-2 mt-4">
            <CRow>
              <CCol xs={12}>
                <div className="text-center" style={{ fontSize: 24, color: '#eb0021' }}>Tổng lãi lỗ</div>
                <div
                  className="text-center"
                  style={{ fontSize: 24, color: '#eb0021' }}
                >
                  {formatVND(data?.total)}
                </div>
              </CCol>

              <CCol xs={6}>
                <div className="text-center">Tổng tiền đặt</div>
                <div
                  className="text-center"
                  style={{ fontSize: 24, color: '#eb0021' }}
                >
                  {formatVND(data?.moneyTotal)}
                </div>
              </CCol>

              <CCol xs={6}>
                <div className="text-center">Tổng tiền trả thưởng</div>
                <div
                  className="text-center"
                  style={{ fontSize: 24, color: '#eb0021' }}
                >
                  {formatVND(data?.addMoneyTotal)}
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
      <div className="page-body-web">{/* <Sidebar /> */}</div>

      <FooterCustom />
    </div>
  )
}

export default LaiLo
