import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface IProps {
  ketQuaKyTruoc: any
  ky: IGame | null
}

const getRandomNumber = () => Math.floor(Math.random() * 10) + 1

const BoxKetQua = (props: IProps) => {
  const { ketQuaKyTruoc, ky } = props

  const [random, setRandom] = useState({
    ball1: getRandomNumber(),
    ball2: getRandomNumber(),
    ball3: getRandomNumber(),
    ball4: getRandomNumber(),
    ball5: getRandomNumber(),
    ball6: getRandomNumber(),
    ball7: getRandomNumber(),
    ball8: getRandomNumber(),
    ball9: getRandomNumber(),
    ball10: getRandomNumber(),
  })

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const updateRandomValues = () => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)
      const seconds = Math.floor(difference / 1000)
      console.log(seconds)

      if (seconds > 0 && seconds < 60) {
        setRandom({
          ball1: getRandomNumber(),
          ball2: getRandomNumber(),
          ball3: getRandomNumber(),
          ball4: getRandomNumber(),
          ball5: getRandomNumber(),
          ball6: getRandomNumber(),
          ball7: getRandomNumber(),
          ball8: getRandomNumber(),
          ball9: getRandomNumber(),
          ball10: getRandomNumber(),
        })
      }else {
        setRandom(ketQuaKyTruoc)
      }
    }
    const intervalId = setInterval(updateRandomValues, 100)
    return () => clearInterval(intervalId)
  }, [ky?.endTime])

  return (
    <div className="box-ketqua-1 px-3 mt-3">
      <div className="ctn p-3">
        <div className="text-center fw-bold">
          Kết quả <span style={{ color: 'red' }}>phiên hiện tại</span>
        </div>
        <div
          style={{ width: 310 }}
          className="m-auto mt-2 mb-3 d-flex gap-2 box-bi-quay-so justify-content-between"
        >
          <div>{random?.ball1}</div>
          <div className="xanh">{random?.ball2}</div>
          <div>{random?.ball3}</div>
          <div className="xanh">{random?.ball4}</div>
          <div>{random?.ball5}</div>
          <div className="xanh">{random?.ball6}</div>
          <div>{random?.ball7}</div>
          <div className="xanh">{random?.ball8}</div>
          <div>{random?.ball9}</div>
          <div className="xanh">{random?.ball10}</div>
        </div>
      </div>
    </div>
  )
}

export default BoxKetQua
