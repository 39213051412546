import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { CButton, CFormInput, CFormLabel } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastSuccess } from "@/components/shared/toast/Toast";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { AppDispatch } from "@/store";
import { getProfile } from "../auth/auth.api";
import { getEntities as getEntitiesBank } from '../BankManagement/banksManagement.api'
import Select, { SingleValue, components } from 'react-select'
import { ISelectValue } from "@/shared/shared-interfaces";
import { CustomOption, CustomSingleValue } from "../UserManagement/UserUpdate";
import { bankSelectors } from "../BankManagement/banksManagement.reducer";
import { IBank } from "@/shared/model/bank.model";

const AddBank = () => {
    const { navigate } = useRouter();
    const dispatch = useDispatch<AppDispatch>()

    const { user } = useSelector((state: RootState) => state.authentication);

    const [cardName, setCardName] = useState(user?.cardName || '');
    const [cardNumber, setCardNumber] = useState(user?.cardNumber || '');
    const [bankName, setBankName] = useState(Number(user?.bankName) || 0);

    const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (index === 1) {
            setCardName(value)
        }
        if (index === 2) {
            setCardNumber(value)
        }
        // if (index === 3) {
        //     setBankName(value)
        // }
    }

    const onSubmit = async () => {
        try {
            const response = await axios.put('/user/' + user?.id, {
                cardName,
                cardNumber,
                bankName
            });
            ToastSuccess('Cập nhật ngân hàng thành công')
            dispatch(getProfile())
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const banks = useSelector(bankSelectors.selectAll)


    const returnBankItem = (items: IBank[], selectBank?: IBank | null) => {
        return items.map((item) => {
            return {
                value: item.bankId,
                label: item.name,
                image: item.avatar,
            }
        })
    }

    useEffect(() => {
        dispatch(getEntitiesBank({ page: 1, limit: 100 }))
        dispatch(getProfile())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const returnOption = (
        optionValue: number | null | undefined,
        array: ISelectValue<string>[],
    ) => {
        if (!optionValue) return null
        if (!array.length) return null
        return array.find(({ value }) => Number(value) === Number(optionValue))
    }

    const customBankItems: any = returnBankItem(banks)

    return (
        <Layout >
            <div className="box-add-bank box-add-bank-wap">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Liên kết ngân hàng</h5>
                </div>

                <div className="box-form">
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="cardName" className="col-sm-2 col-form-label text-nowrap">Chủ tài khoản</CFormLabel>
                        <CFormInput onChange={(e) => onChange(1, e)} type="text" id="cardName" placeholder="Nhập tên thật" value={cardName} />
                    </div>
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="cardNumber" className="col-sm-2 col-form-label text-nowrap">Số tài khoản</CFormLabel>
                        <CFormInput onChange={(e) => onChange(2, e)} type="text" id="cardNumber" placeholder="Nhập số tài khoản" value={cardNumber} />
                    </div>
                    <div className="d-flex form-group-custom flex-column">
                        <CFormLabel htmlFor="bankName" className="col-sm-2 col-form-label text-nowrap">Ngân hàng</CFormLabel>
                        {/* <CFormInput onChange={(e) => onChange(3, e)} type="text" id="bankName" placeholder="Nhập ngân hàng" value={bankName} /> */}
                        <Select
                            // menuIsOpen={true}
                            defaultValue={null}
                            className="custom-select form-height-44"
                            classNamePrefix="react-select"
                            value={
                                returnOption(
                                    bankName as any,
                                    customBankItems,
                                ) as any
                            }
                            onChange={(
                                newValue: SingleValue<ISelectValue<string>>,
                            ) => {
                                setBankName(Number(newValue?.value) || 0)
                            }}
                            noOptionsMessage={() => <>Không có ngân hàng nào</>}
                            id={'bankName'}
                            options={customBankItems}
                            placeholder={'Chọn Ngân Hàng'}
                            isClearable={true}
                            closeMenuOnSelect={true}
                            menuPosition={'absolute'}
                            name="bankName"
                            components={{
                                Option: CustomOption,
                                SingleValue: CustomSingleValue,
                            }}
                        />

                    </div>

                    <div className="px-3 mt-4">
                        <CButton className="w-100 submit" onClick={onSubmit} disabled={(user?.cardName && user?.cardNumber && user?.bankName) ? true : false}>Cập nhật</CButton>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddBank;