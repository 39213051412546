import { RootState } from '@/reducers'
import { IGame } from '@/shared/model/game.model'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../shared/config/axios-interceptor'
import { getProfile } from '../auth/auth.api'
import { formatVND } from '@/shared/utils/ultils'
import SModal from '@/components/shared/Modal/SModal'
import {
  CCol,
  CContainer,
  CForm,
  CModalBody,
  CModalFooter,
  CRow,
} from '@coreui/react-pro'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import { mapBiDatCuocGroup } from '@/shared/enumeration/ResponseStatus'
import {
  ToastError,
  ToastErrorBottom,
  ToastSuccess,
  ToastSuccessBottom,
} from '@/components/shared/toast/Toast'
import KyQuay from './KyQuay'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import { historySelectors } from '../HistoryManagement/historyManagement.reducer'
import { IHistory } from '@/shared/model/history.model'
import { IGameName } from '@/shared/model/gameName.model'
import { IGameSidebar } from '@/shared/model/gameSidebar.model'
import BoxTienCuoc from './components/BoxTienCuoc'
import logoWeb from '../../../assets/img/logo-9.gif'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import BoxKetQua from './BoxKetQua'
import Header from './Header'
import FooterCustom from './FooterCustom'
import { HistoryState } from '@/shared/enumeration/historyState'

const Home1 = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const [game, setGame] = useState<IGameName | null>(null)

  const getGame = async (id: number) => {
    try {
      const response = await axios.get('/game/' + 1)
      setGame(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getGame(Number(1) ?? 1)
  }, [1])

  const [listKetQua, setListKetQua] = useState<IGame[]>([])

  const getListKetQua = async () => {
    try {
      const response = await axios.get(
        '/draws?gameId=' + 1 + '&status=closed&sortBy=id&sortOrder=DESC',
      )
      setListKetQua(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [soTien, setSoTien] = useState<number>(100)
  const [boxCuoc, setBoxCuoc] = useState<IGameSidebar[]>([])
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const [ky, setKy] = useState<IGame | null>(null)
  const [load, setLoad] = useState(false)
  const [disableDatCuoc, setDisableDatCuoc] = useState(false)
  const { user } = useSelector((state: RootState) => state.authentication)
  const historys = useSelector(historySelectors.selectAll)

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer,
  )
  const { filterState } = initialState

  const getBoxCuoc = async () => {
    try {
      const response = await axios.get('/bidatcuoc/listcuoc/' + 1)
      setBoxCuoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current/' + 1)
      setKy(response.data)
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get(
        '/draws/get-result-ket-qua-ky-truoc/' + 1,
      )
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getListKetQua()
    getBoxCuoc()
    getKy()
    getKetQuaKyTruoc()
    dispatch(getProfile())
    dispatch(
      getEntities({
        ...filterState,
        userId: Number(user?.id) ?? 1,
        sortBy: 'historyId',
        sortOrder: 'DESC',
        status: null,
        state: HistoryState.SHOW
      }),
    )
  }, [load, game])

  const renderKQ = (item: any) => {
    if (item.isWin || item.isWin == 0) {
      if (item.isWin == 1) {
        return (
          <div className="text-success">
            Đã trúng thưởng: {formatVND(item.addMoney ?? 0)}
          </div>
        )
      }
      return <div className="text-danger">Không trúng thưởng</div>
    }
    return <div className="text-warning">Chưa mở thưởng</div>
  }

  const initialValues: any = {}

  return (
    <>
      <div className="container-web">
        <Header />
        <div className="content-header">
          <div className="page-wap-game page-pc-game d-flex flex-column">
            <CContainer>
              <div className="px-3 mt-4">
                <CRow className="box-top g-3">
                  <CCol xs={4}>
                    <div className="left text-center h-100">
                      <img height={130} className="w-auto" src={logoWeb} />
                    </div>
                  </CCol>
                  <CCol xs={4}>
                    <div className="left h-100">
                      <div className="fw-bold text-center">
                        Thời gian đặt cược{' '}
                        <span style={{ color: 'red' }}>phiên kế tiếp</span>
                      </div>
                      <KyQuay
                        load={load}
                        ky={ky}
                        setDisableDatCuoc={setDisableDatCuoc}
                        setLoad={setLoad}
                      />
                    </div>
                  </CCol>
                  <CCol xs={4}>
                    <BoxKetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} />
                  </CCol>
                </CRow>
              </div>

              <div className="main-content-game">
                <div className="tab tab1 px-3 pt-3">
                  <div className="row g-3 pb-5 row-cols-5">
                    {boxCuoc.map((item, index) => {
                      return (
                        <CCol key={index}>
                          <div className="item-box-dat-cuoc-1">
                            <Formik
                              enableReinitialize
                              initialValues={initialValues}
                              onSubmit={async (values, { resetForm }) => {
                                let listData: IBiDatCuoc[] = []
                                let total = 0

                                item.biDatCuoc.forEach((it, id) => {
                                  const propertyName: any =
                                    values[`bien_${it.biDatCuocId}`]
                                  console.log(propertyName)
                                  if (
                                    propertyName &&
                                    Number(propertyName) > 0
                                  ) {
                                    total = total + Number(propertyName)
                                    listData.push({
                                      ...it,
                                      amount: Number(propertyName),
                                    })
                                  }
                                })

                                if(total > (user?.money ?? 0)) {
                                  ToastErrorBottom('Số dư không đủ')
                                  return;
                                }

                                if(Number(total) > Number(user?.money ?? 0)) {
                                  ToastErrorBottom('Số dư không đủ')
                                  return;
                                }

                                if(Number(total) <= 0) {
                                  ToastErrorBottom('Số tiền không hợp lệ')
                                  return;
                                }

                                try {
                                  const data = {
                                    money: soTien,
                                    kyId: ky?.id,
                                    listDatCuoc: listData,
                                  }
                                  const response = await axios.post(
                                    '/history/create-all-web',
                                    data,
                                  )
                                  if(response){
                                    ToastSuccessBottom('Đặt cược thành công')
                                    resetForm()
                                    dispatch(getProfile())
                                    dispatch(
                                      getEntities({
                                        ...filterState,
                                        userId: Number(user?.id) ?? 1,
                                        sortBy: 'historyId',
                                        sortOrder: 'DESC',
                                        status: null,
                                        state: HistoryState.SHOW
                                      }),
                                    )
                                  }
                                } catch (error) {
                                  resetForm()
                                  console.log(error)
                                }
                              }}
                            >
                              {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                                <CForm className="p-0" onSubmit={handleSubmit}>
                                  <div className="title">{item.name}</div>
                                  <div className="tab-header">
                                    <div className="name-title">Con số</div>
                                    <div className="rate-title">Tỷ lệ</div>
                                    <div className="amount-title">Số lượng</div>
                                  </div>

                                  <div className="box-content">
                                    {item.biDatCuoc.map((it, id) => {
                                      return (
                                        <div
                                          key={id}
                                          className="mb-2 d-flex align-items-center"
                                        >
                                          <div className="name">{it.name}</div>
                                          <div className="rate">{it.rate}</div>
                                          <div className="input">
                                            <input
                                              name={`bien_${it.biDatCuocId}`}
                                              value={
                                                values[
                                                  `bien_${it.biDatCuocId}`
                                                ] ?? ''
                                              }
                                              onChange={e => {
                                                const { value } = e.target;
                                                if (/^\d*$/.test(value)) {
                                                  setFieldValue(`bien_${it.biDatCuocId}`, value);
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )
                                    })}

                                    <button
                                      type="submit"
                                      className="btn-submit-1 mt-3" disabled={isSubmitting}
                                    >
                                      XÁC NHẬN ĐẶT
                                    </button>
                                  </div>
                                </CForm>
                              )}
                            </Formik>
                          </div>
                        </CCol>
                      )
                    })}
                  </div>

                  <h3 className="fw-bold text-center mb-3">
                    Danh sách đặt cược
                  </h3>
                  <div className="table-history-wap">
                    <div className="header-table d-flex">
                      <div>Kỳ</div>
                      <div>Giải</div>
                      <div>Loại cược</div>
                      <div>Tỉ lệ</div>
                      <div>Tiền cược</div>
                      <div>Trả thưởng</div>
                      <div>Thời gian</div>
                    </div>

                    {historys.map((item, index) => {
                      return (
                        <div className="content-table d-flex" key={index}>
                          <div>{item.KyId}</div>
                          <div>{item?.biDatCuoc?.gameSidebar?.name}</div>
                          <div>{item?.biDatCuoc?.name}</div>
                          <div>{item?.rate}</div>
                          <div>{formatVND(item?.money ?? 0)}</div>
                          <div>{formatVND(item?.addMoney ?? 0)}</div>
                          <div>
                            {dayjs(item?.createdDate).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="mb-5"></div>
              </div>
            </CContainer>
          </div>
        </div>
        <div className="page-body-web"></div>

        <FooterCustom />
      </div>
    </>
  )
}

export default Home1
